var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import styles from './BackToTopButton.module.scss';
var BackToTopButton = function () {
    var _a = useState(false), isVisible = _a[0], setIsVisible = _a[1];
    var toggleVisibility = function () {
        if (window.scrollY > 100) {
            setIsVisible(true);
        }
        else {
            setIsVisible(false);
        }
    };
    var scrollToTop = function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(function () {
        window.addEventListener('scroll', toggleVisibility);
        return function () {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);
    return (_jsx("div", __assign({ className: styles['back-to-top'] }, { children: isVisible &&
            _jsx("button", __assign({ onClick: scrollToTop, className: styles['back-to-top-button'] }, { children: "\u2191" })) })));
};
export default BackToTopButton;

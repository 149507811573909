var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import style from './Modal.module.scss';
export var Modal = function (_a) {
    var onClose = _a.onClose, message = _a.message;
    return (_jsx("div", __assign({ className: style.modal }, { children: _jsxs("div", __assign({ className: style.modalContent }, { children: [_jsx("span", __assign({ className: style.close, onClick: onClose }, { children: "\u00D7" })), _jsx("p", __assign({ className: style.message }, { children: message }))] })) })));
};

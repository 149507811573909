var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import LinkTool from '@editorjs/link';
import Embed from '@editorjs/embed';
import AttachesTool from '@editorjs/attaches';
import { useNotification } from '../../../NotificationContext';
import styles from './ArticleDetail.module.scss';
var ArticleDetail = function () {
    var id = useParams().id;
    var _a = useState(null), article = _a[0], setArticle = _a[1];
    var navigate = useNavigate();
    var _b = useState(''), userRole = _b[0], setUserRole = _b[1];
    var _c = useNotification(), notifySuccess = _c.notifySuccess, notifyError = _c.notifyError;
    var editorInstance = useRef(null);
    var _d = useState(false), articleRead = _d[0], setArticleRead = _d[1];
    useEffect(function () {
        var token = localStorage.getItem('accessToken');
        if (!token) {
            console.error('AccessToken is missing in localStorage');
            return;
        }
        var fetchUserProfile = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, profileData, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch("/api/v1/user-profile/", {
                                method: 'GET',
                                headers: {
                                    'Authorization': "Bearer ".concat(token),
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        profileData = _a.sent();
                        if (response.ok) {
                            setUserRole(profileData.role);
                        }
                        else {
                            console.error('Ошибка при получении профиля пользователя:', profileData);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Ошибка:', error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchUserProfile();
        var fetchArticle = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, status_1, text, data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch("/api/v1/articles/".concat(id), {
                                method: 'GET',
                                headers: {
                                    'Authorization': "Bearer ".concat(token),
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        status_1 = response.status;
                        return [4 /*yield*/, response.text()];
                    case 2:
                        text = _a.sent();
                        console.log('Response status:', status_1);
                        if (status_1 !== 200) {
                            console.error('Ошибка при получении статьи:', text);
                            throw new Error("\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0438 \u0441\u0442\u0430\u0442\u044C\u0438: ".concat(status_1));
                        }
                        try {
                            data = JSON.parse(text);
                            setArticle(data);
                        }
                        catch (error) {
                            console.error('Ошибка при парсинге JSON:', error);
                            console.error('Текст ответа:', text);
                            if (text.startsWith('<')) {
                                console.error('Получен HTML вместо JSON. Возможно, это страница ошибки.');
                            }
                            throw new Error('Получен недопустимый JSON');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.error('Ошибка:', error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchArticle();
        return function () {
            if (editorInstance.current) {
                editorInstance.current.destroy();
                editorInstance.current = null;
            }
        };
    }, [id]);
    useEffect(function () {
        if (article && article.content) {
            editorInstance.current = new EditorJS({
                holder: 'editorjs-viewer',
                tools: {
                    header: Header,
                    image: ImageTool,
                    linkTool: LinkTool,
                    embed: Embed,
                    attaches: AttachesTool,
                },
                data: article.content,
                readOnly: true,
            });
            return function () {
                if (editorInstance.current) {
                    editorInstance.current.destroy();
                    editorInstance.current = null;
                }
            };
        }
    }, [article]);
    var markArticleAsRead = function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, requestBody, response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('Marking article as read...');
                    token = localStorage.getItem('accessToken');
                    if (!token) {
                        console.error('AccessToken is missing in localStorage');
                        return [2 /*return*/];
                    }
                    requestBody = { article_id: Number(id) };
                    console.log('Request body:', requestBody);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/v1/mark_article_as_read/", {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': "Bearer ".concat(token),
                            },
                            body: JSON.stringify(requestBody),
                        })];
                case 2:
                    response = _a.sent();
                    if (response.ok) {
                        notifySuccess('Статья отмечена как прочитанная.');
                        console.log('Article marked as read successfully.');
                    }
                    else {
                        notifyError('Ошибка при отметке статьи как прочитанной.');
                        console.error('Error marking article as read:', response.statusText);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error('Ошибка:', error_3);
                    notifyError('Ошибка при отметке статьи как прочитанной.');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleScroll = function () {
        var scrollTop = window.scrollY || document.documentElement.scrollTop;
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        var docHeight = document.documentElement.scrollHeight;
        if (scrollTop + windowHeight >= docHeight - 10 && !articleRead) {
            console.log('User reached the bottom of the page');
            markArticleAsRead();
            setArticleRead(true);
        }
    };
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        return function () { return window.removeEventListener('scroll', handleScroll); };
    }, [articleRead]);
    if (!article)
        return _jsx("div", { children: "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430..." });
    var handleEdit = function () {
        navigate("/articleupdate/".concat(id), { state: { article: article } });
    };
    var handleDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var confirmed, token, response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    confirmed = window.confirm('Вы уверены, что хотите удалить статью?');
                    if (!confirmed)
                        return [2 /*return*/];
                    token = localStorage.getItem('accessToken');
                    if (!token) {
                        console.error('AccessToken is missing in localStorage');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch("/api/v1/articles/".concat(id, "/delete/"), {
                            method: 'DELETE',
                            headers: {
                                'Authorization': "Bearer ".concat(token),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (response.ok) {
                        notifySuccess('Статья успешно удалена!');
                        navigate('/');
                    }
                    else {
                        notifyError('Ошибка при удалении статьи');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    console.error('Ошибка:', error_4);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: styles.articleBlock }, { children: [_jsx("h1", { children: article === null || article === void 0 ? void 0 : article.title }), _jsx("div", __assign({ className: styles.editorWrapper }, { children: _jsx("div", { id: "editorjs-viewer", className: styles.editor }) })), _jsxs("p", { children: ["\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F: ", article === null || article === void 0 ? void 0 : article.category_info.name] }), _jsxs("p", { children: ["\u0414\u0430\u0442\u0430 \u043F\u0443\u0431\u043B\u0438\u043A\u0430\u0446\u0438\u0438: ", new Date(article === null || article === void 0 ? void 0 : article.time_create).toLocaleDateString()] }), (userRole === 'Администратор' || userRole === 'Руководитель') && (_jsxs("div", { children: [_jsx("button", __assign({ className: styles.editButton, onClick: handleEdit }, { children: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C" })), _jsx("button", __assign({ className: styles.deleteButton, onClick: handleDelete }, { children: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C" }))] }))] })));
};
export default ArticleDetail;

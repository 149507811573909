var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { registerSchema } from '../../../@types/registerTypes';
import { EmailInput } from './Inputs/EmailInput';
import { PasswordInput } from './Inputs/PasswordInput';
import { ConfirmPasswordInput } from './Inputs/ConfirmPasswordInput';
import FirstNameInput from './Inputs/FirstNameInput';
import LastNameInput from './Inputs/LastNameInput';
import { Modal } from './Modal'; // Предполагаем, что есть компонент Modal
import s from './Register.module.scss';
import { NavLink } from 'react-router-dom';
function Register() {
    var _this = this;
    var _a = useState(false), showModal = _a[0], setShowModal = _a[1];
    var methods = useForm({
        resolver: zodResolver(registerSchema),
        mode: 'onBlur',
    });
    var submit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetch("/api/v1/user-register/", {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(data),
                        })];
                case 1:
                    response = _a.sent();
                    if (response.ok) {
                        setShowModal(true);
                    }
                    else {
                        console.error('Ошибка аутентификации:', response.statusText);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Ошибка при отправке запроса:', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var error = function (errors) {
        console.log(errors);
    };
    return (_jsxs("div", __assign({ className: s.root }, { children: [_jsxs("div", __assign({ className: s.blockLogo }, { children: [_jsx("img", { src: "/img/logo.svg", alt: "Logo" }), _jsx("span", { children: "\u0414\u043E\u0431\u0440\u043E \u043F\u043E\u0436\u0430\u043B\u043E\u0432\u0430\u0442\u044C!" })] })), _jsx("div", __assign({ className: s.register }, { children: _jsx("div", __assign({ className: s.register_wrapper }, { children: _jsxs(FormProvider, __assign({}, methods, { children: [_jsxs("form", __assign({ onSubmit: methods.handleSubmit(submit, error) }, { children: [_jsx("h2", { children: "\u0424\u043E\u0440\u043C\u0430 \u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438" }), _jsx(FirstNameInput, {}), _jsx(LastNameInput, {}), _jsx(EmailInput, {}), _jsx(PasswordInput, {}), _jsx(ConfirmPasswordInput, {}), _jsx("div", __assign({ className: s.btn }, { children: _jsx("button", __assign({ type: "submit", disabled: !methods.formState.isValid }, { children: "\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C\u0441\u044F" })) })), _jsxs("div", __assign({ className: s.backLogin }, { children: [_jsx("span", { children: "\u0423\u0436\u0435 \u0435\u0441\u0442\u044C \u0430\u043A\u043A\u0430\u0443\u043D\u0442?" }), _jsx(NavLink, __assign({ to: "/login" }, { children: "\u0412\u043E\u0439\u0442\u0438" }))] }))] })), _jsx(NavLink, __assign({ to: "/login" }, { children: showModal && _jsx(Modal, { onClose: function () { return setShowModal(false); }, message: "\u0412\u044B \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0437\u0430\u0440\u0435\u0433\u0435\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043B\u0438\u0441\u044C, \u043E\u0436\u0438\u0434\u0430\u0439\u0442\u0435 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 \u0412\u0430\u0448\u0435\u0439 \u0443\u0447\u0435\u0442\u043D\u043E\u0439 \u0437\u0430\u043F\u0438\u0441\u0438" }) }))] })) })) }))] })));
}
export default Register;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import s from './Articles.module.scss';
import Article from './Article/Article';
import { Skeleton } from '../../Skeleton';
export var Articles = function () {
    var _a = useState([]), articles = _a[0], setArticles = _a[1];
    var _b = useState([]), categories = _b[0], setCategories = _b[1];
    var _c = useState(''), selectedCategory = _c[0], setSelectedCategory = _c[1];
    var _d = useState(true), isLoading = _d[0], setIsLoading = _d[1];
    var navigate = useNavigate(); // Initialize useNavigate
    useEffect(function () {
        var token = localStorage.getItem('accessToken');
        // Загрузка категорий
        var fetchCategories = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, filteredData, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, fetch('/api/v1/categories/', {
                                method: 'GET',
                                headers: {
                                    'Authorization': "Bearer ".concat(token),
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) return [3 /*break*/, 3];
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        filteredData = data.filter(function (category) { return category.name !== 'Тесты'; });
                        setCategories(filteredData);
                        return [3 /*break*/, 4];
                    case 3:
                        console.error('Ошибка при получении категорий:', response.statusText);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        console.error('Ошибка:', error_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        // Загрузка статей
        var fetchArticles = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, fetch('/api/v1/articles/', {
                                method: 'GET',
                                headers: {
                                    'Authorization': "Bearer ".concat(token),
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) return [3 /*break*/, 3];
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        data.sort(function (a, b) { return new Date(b.time_create).getTime() - new Date(a.time_create).getTime(); });
                        setArticles(data);
                        return [3 /*break*/, 4];
                    case 3:
                        console.error('Ошибка при получении статей:', response.statusText);
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_2 = _a.sent();
                        console.error('Ошибка:', error_2);
                        return [3 /*break*/, 6];
                    case 6:
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        fetchCategories();
        fetchArticles();
    }, []);
    // Фильтрация статей по выбранной категории
    var filteredArticles = articles.filter(function (article) { return article.category_info.name === selectedCategory || selectedCategory === ''; });
    return (_jsxs("div", __assign({ className: s.container }, { children: [_jsx("div", __assign({ className: s.categories }, { children: _jsxs("ul", __assign({ className: s.ul }, { children: [_jsx("li", __assign({ className: "".concat(!selectedCategory ? s.active : '', " ").concat(s.li), onClick: function () { return setSelectedCategory(''); } }, { children: "\u0412\u0441\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438" })), categories.map(function (category) { return (_jsx("li", __assign({ className: "".concat(selectedCategory === category.name ? s.active : '', " ").concat(s.li), onClick: function () { return setSelectedCategory(category.name); } }, { children: category.name }), category.id)); })] })) })), _jsxs("div", __assign({ className: s.articles }, { children: [_jsxs("div", __assign({ className: s.header }, { children: [_jsx("h2", __assign({ className: s.artsH2 }, { children: "\u041E\u0431\u0443\u0447\u0435\u043D\u0438\u0435" })), _jsx("button", __assign({ className: s.addButton, onClick: function () { return navigate('/articlecreate'); } }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0442\u0430\u0442\u044C\u044E" }))] })), isLoading ? (_jsx(Skeleton, {})) : (filteredArticles.map(function (article, index) { return (_jsx(Article, __assign({}, article), index)); }))] }))] })));
};

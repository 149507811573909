var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import s from './Input.module.scss';
import { TextField } from '@mui/material';
var FirstNameInput = function () {
    var _a = useFormContext(), register = _a.register, control = _a.control, errors = _a.formState.errors;
    return (_jsx(_Fragment, { children: _jsx(Controller, { name: "first_name", control: control, rules: {
                required: "Это поле обязательно для заполнения",
                minLength: {
                    value: 2,
                    message: "Имя должно быть не менее 2 символов"
                }
            }, render: function (_a) {
                var _b, _c;
                var field = _a.field;
                return (_jsxs("div", __assign({ className: s.first_name }, { children: [_jsx(TextField, __assign({}, field, { variant: "standard", error: Boolean((_b = errors.first_name) === null || _b === void 0 ? void 0 : _b.message), placeholder: "\u0418\u043C\u044F", type: "text" }, register('first_name'), { fullWidth: true })), _jsx("div", __assign({ className: s.error }, { children: ((_c = errors.first_name) === null || _c === void 0 ? void 0 : _c.message) && (_jsx("p", { children: _jsx(_Fragment, { children: errors.first_name.message }) })) }))] })));
            } }) }));
};
export default FirstNameInput;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import s from './Input.module.scss';
import { FormControl, IconButton, Input, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
export var PasswordInput = function () {
    var _a = useFormContext(), register = _a.register, control = _a.control, errors = _a.formState.errors;
    var _b = React.useState(false), showPassword = _b[0], setShowPassword = _b[1];
    var handleClickShowPassword = function () { return setShowPassword(function (show) { return !show; }); };
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    return (_jsx(_Fragment, { children: _jsx(Controller, { name: "password", control: control, render: function (_a) {
                var _b, _c;
                var field = _a.field;
                return (_jsxs("div", __assign({ className: s.password }, { children: [_jsx(FormControl, __assign({ sx: { '&:focus': '#000' }, variant: "standard", fullWidth: true }, { children: _jsx(Input, __assign({ error: Boolean((_b = errors.password) === null || _b === void 0 ? void 0 : _b.message), placeholder: "\u041F\u0430\u0440\u043E\u043B\u044C" }, register('password'), { sx: { '&:focus': '#000' }, id: "standard-adornment-password", type: showPassword ? 'text' : 'password', endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ sx: { color: '#000' }, "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword }, { children: showPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) })) })) })) })), _jsx("div", __assign({ className: s.error }, { children: ((_c = errors.password) === null || _c === void 0 ? void 0 : _c.message) && (_jsx("p", { children: _jsx(_Fragment, { children: errors.password.message }) })) }))] })));
            } }) }));
};

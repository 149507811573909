var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
var NotificationContext = createContext({
    notifySuccess: function () { },
    notifyError: function () { },
});
export var useNotification = function () { return useContext(NotificationContext); };
export var NotificationProvider = function (_a) {
    var children = _a.children;
    var notifySuccess = useCallback(function (message) {
        toast.success(message);
    }, []);
    var notifyError = useCallback(function (message) {
        toast.error(message);
    }, []);
    return (_jsxs(NotificationContext.Provider, __assign({ value: { notifySuccess: notifySuccess, notifyError: notifyError } }, { children: [children, _jsx(ToastContainer, {})] })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ContentLoader from 'react-content-loader';
export var Skeleton = function () {
    return (_jsxs(ContentLoader, __assign({ speed: 2, width: 795, height: 199, viewBox: "0 0 795 199", backgroundColor: "#f3f3f3", foregroundColor: "#ecebeb" }, { children: [_jsx("rect", { x: "10", y: "10", rx: "7", ry: "7", width: "229", height: "179" }), _jsx("rect", { x: "277", y: "22", rx: "7", ry: "7", width: "128", height: "20" }), _jsx("rect", { x: "278", y: "56", rx: "7", ry: "7", width: "458", height: "59" }), _jsx("rect", { x: "280", y: "160", rx: "7", ry: "7", width: "178", height: "27" })] })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import s from './Input.module.scss';
import { TextField } from '@mui/material';
export var EmailInput = function () {
    var _a = useFormContext(), register = _a.register, control = _a.control, errors = _a.formState.errors;
    return (_jsx(_Fragment, { children: _jsx(Controller, { name: "email", control: control, render: function () {
                var _a, _b;
                return (_jsxs("div", __assign({ className: s.email }, { children: [_jsx(TextField, __assign({ variant: "standard", id: "standard-basic", error: Boolean((_a = errors.email) === null || _a === void 0 ? void 0 : _a.message), placeholder: "Email \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F", type: "text" }, register('email'), { fullWidth: true })), _jsx("div", __assign({ className: s.error }, { children: ((_b = errors.email) === null || _b === void 0 ? void 0 : _b.message) && (_jsx("p", { children: _jsx(_Fragment, { children: errors.email.message }) })) }))] })));
            } }) }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import Embed from '@editorjs/embed';
import AttachesTool from '@editorjs/attaches';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../NotificationContext';
import styles from './ArticleCreate.module.scss';
var ArticleForm = function () {
    var _a = useState(''), title = _a[0], setTitle = _a[1];
    var _b = useState(''), selectedCategoryId = _b[0], setSelectedCategoryId = _b[1];
    var _c = useState([]), categories = _c[0], setCategories = _c[1];
    var editorInstance = useRef(null);
    var _d = useNotification(), notifySuccess = _d.notifySuccess, notifyError = _d.notifyError;
    var navigate = useNavigate();
    useEffect(function () {
        var fetchCategories = function () { return __awaiter(void 0, void 0, void 0, function () {
            var accessToken, response, categoriesData, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = localStorage.getItem('accessToken');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fetch('/api/v1/categories/', {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': "Bearer ".concat(accessToken),
                                },
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response.ok)
                            throw new Error('Ошибка при запросе категорий');
                        return [4 /*yield*/, response.json()];
                    case 3:
                        categoriesData = _a.sent();
                        setCategories(categoriesData);
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error('Ошибка при получении категорий:', error_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchCategories();
        editorInstance.current = new EditorJS({
            holder: 'editorjs',
            tools: {
                header: Header,
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            uploadByFile: function (file) { return __awaiter(void 0, void 0, void 0, function () {
                                var url, error_2;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 2, , 3]);
                                            return [4 /*yield*/, uploadImage(file)];
                                        case 1:
                                            url = _a.sent();
                                            return [2 /*return*/, {
                                                    success: 1,
                                                    file: {
                                                        url: url,
                                                    },
                                                }];
                                        case 2:
                                            error_2 = _a.sent();
                                            console.error('Ошибка загрузки изображения:', error_2);
                                            notifyError('Ошибка загрузки изображения');
                                            return [2 /*return*/, {
                                                    success: 0,
                                                }];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); },
                        },
                    },
                },
                embed: Embed,
                attaches: {
                    class: AttachesTool,
                    config: {
                        uploader: {
                            uploadByFile: function (file) { return __awaiter(void 0, void 0, void 0, function () {
                                var url, error_3;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 2, , 3]);
                                            return [4 /*yield*/, uploadFile(file)];
                                        case 1:
                                            url = _a.sent();
                                            return [2 /*return*/, {
                                                    success: 1,
                                                    file: {
                                                        url: url,
                                                        name: file.name,
                                                    },
                                                }];
                                        case 2:
                                            error_3 = _a.sent();
                                            console.error('Ошибка загрузки файла:', error_3);
                                            notifyError('Ошибка загрузки файла');
                                            return [2 /*return*/, {
                                                    success: 0,
                                                }];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); },
                        },
                    },
                },
            },
        });
        return function () {
            if (editorInstance.current) {
                editorInstance.current.destroy();
                editorInstance.current = null;
            }
        };
    }, []);
    function uploadFile(file) {
        return __awaiter(this, void 0, void 0, function () {
            var accessToken, formData, response, result, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = localStorage.getItem('accessToken');
                        formData = new FormData();
                        formData.append('file', file);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fetch('/api/v1/document-upload/', {
                                method: 'POST',
                                headers: {
                                    'Authorization': "Bearer ".concat(accessToken),
                                },
                                body: formData,
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error('Ошибка загрузки файла');
                        }
                        return [4 /*yield*/, response.json()];
                    case 3:
                        result = _a.sent();
                        return [2 /*return*/, result.file_url];
                    case 4:
                        error_4 = _a.sent();
                        console.error('Ошибка загрузки файла:', error_4);
                        throw error_4;
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    var uploadImage = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var formData, accessToken, response, errorText, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formData = new FormData();
                    formData.append('image', file);
                    accessToken = localStorage.getItem('accessToken');
                    return [4 /*yield*/, fetch('/api/v1/image-upload/', {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'Authorization': "Bearer ".concat(accessToken),
                            }
                        })];
                case 1:
                    response = _a.sent();
                    if (!!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.text()];
                case 2:
                    errorText = _a.sent();
                    console.error("\u041E\u0448\u0438\u0431\u043A\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0438 \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F: ".concat(errorText));
                    throw new Error("\u041E\u0448\u0438\u0431\u043A\u0430 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0438 \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F: ".concat(errorText));
                case 3: return [4 /*yield*/, response.json()];
                case 4:
                    result = _a.sent();
                    console.log('Загруженное изображение:', result.image);
                    return [2 /*return*/, result.image];
            }
        });
    }); };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, savedData, response, errorText, error_5;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    accessToken = localStorage.getItem('accessToken');
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, , 7]);
                    return [4 /*yield*/, ((_a = editorInstance.current) === null || _a === void 0 ? void 0 : _a.save())];
                case 2:
                    savedData = _b.sent();
                    if (!savedData) {
                        notifyError('Не удалось сохранить данные из редактора');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, fetch('/api/v1/articles/create/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify({
                                title: title,
                                content: savedData,
                                category: selectedCategoryId,
                            }),
                        })];
                case 3:
                    response = _b.sent();
                    if (!!response.ok) return [3 /*break*/, 5];
                    return [4 /*yield*/, response.text()];
                case 4:
                    errorText = _b.sent();
                    console.error("\u041F\u0440\u043E\u0431\u043B\u0435\u043C\u0430 \u0441 \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u0435\u043C \u0441\u0442\u0430\u0442\u044C\u0438: ".concat(errorText));
                    notifyError("\u041F\u0440\u043E\u0431\u043B\u0435\u043C\u0430 \u0441 \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u0435\u043C \u0441\u0442\u0430\u0442\u044C\u0438: ".concat(errorText));
                    return [2 /*return*/];
                case 5:
                    notifySuccess('Статья успешно создана!');
                    navigate('/articles');
                    return [3 /*break*/, 7];
                case 6:
                    error_5 = _b.sent();
                    notifyError("\u041E\u0448\u0438\u0431\u043A\u0430 \u043F\u0440\u0438 \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u0438 \u0441\u0442\u0430\u0442\u044C\u0438: ".concat(error_5.message));
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { children: _jsxs("form", __assign({ onSubmit: handleSubmit, className: styles.formContainer }, { children: [_jsx("h2", __assign({ className: styles.articleCreateH2 }, { children: "\u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u0441\u0442\u0430\u0442\u044C\u0438" })), _jsxs("div", __assign({ className: styles.formGroup }, { children: [_jsx("label", __assign({ htmlFor: "title", className: styles.formLabel }, { children: "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A:" })), _jsx("input", { id: "title", type: "text", value: title, onChange: function (e) { return setTitle(e.target.value); }, required: true, className: styles.input })] })), _jsxs("div", __assign({ className: styles.formGroup }, { children: [_jsx("label", __assign({ htmlFor: "content", className: styles.formLabel }, { children: "\u0421\u043E\u0434\u0435\u0440\u0436\u0430\u043D\u0438\u0435:" })), _jsx("div", { id: "editorjs", className: styles.editor })] })), _jsxs("div", __assign({ className: styles.formGroup }, { children: [_jsx("label", __assign({ htmlFor: "category", className: styles.formLabel }, { children: "\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F:" })), _jsxs("select", __assign({ id: "category", value: selectedCategoryId, onChange: function (e) { return setSelectedCategoryId(e.target.value); }, required: true, className: styles.selectCategory }, { children: [_jsx("option", __assign({ value: "" }, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044E" })), categories.map(function (_a) {
                                    var id = _a.id, name = _a.name;
                                    return (_jsx("option", __assign({ value: id }, { children: name }), id));
                                })] }))] })), _jsx("button", __assign({ type: "submit", className: styles.submitButton }, { children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C" }))] })) }));
};
export default ArticleForm;

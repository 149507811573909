var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
import s from './Article.module.scss';
var Article = function (_a) {
    var id = _a.id, title = _a.title, time_create = _a.time_create, category_info = _a.category_info;
    return (_jsx("div", __assign({ className: s.article }, { children: _jsxs(NavLink, __assign({ to: "/article/".concat(id), className: s.articles }, { children: [_jsx("div", __assign({ className: s.wrapper_img }, { children: _jsx("img", { src: "img/header/article.png", alt: "\u0421\u0442\u0430\u0442\u044C\u044F" }) })), _jsxs("div", __assign({ className: s.wrapper_right_block }, { children: [_jsx("div", __assign({ className: s.headline }, { children: _jsx("h4", { children: title }) })), _jsx("div", { className: s.text }), _jsx("div", __assign({ className: s.date }, { children: _jsxs("p", { children: [new Date(time_create).toLocaleDateString(), _jsx("span", { children: category_info.name })] }) }))] }))] })) })));
};
export default Article;

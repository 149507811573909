var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import s from './Input.module.scss';
import { FormControl, IconButton, Input, InputAdornment, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
export var ConfirmPasswordInput = function () {
    var _a = useFormContext(), register = _a.register, watch = _a.watch, control = _a.control, getValues = _a.getValues, errors = _a.formState.errors;
    var _b = React.useState(false), showConfirmPassword = _b[0], setShowConfirmPassword = _b[1];
    var handleClickShowConfirmPassword = function () {
        return setShowConfirmPassword(function (show) { return !show; });
    };
    var handleMouseDownConfirmPassword = function (event) {
        event.preventDefault();
    };
    return (_jsx(_Fragment, { children: _jsx(Controller, { name: "confirm_password", control: control, render: function () {
                var _a, _b;
                return (_jsxs("div", __assign({ className: s.confirmPassword }, { children: [_jsx(FormControl, __assign({ sx: { '&:focus': '#000' }, variant: "standard", fullWidth: true }, { children: _jsx(Input, __assign({ error: Boolean((_a = errors.confirm_password) === null || _a === void 0 ? void 0 : _a.message), placeholder: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 \u043F\u0430\u0440\u043E\u043B\u044C" }, register('confirm_password'), { sx: { '&:focus': '#000' }, type: showConfirmPassword ? 'text' : 'password', endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ sx: { color: '#000' }, "aria-label": "toggle password visibility", onClick: handleClickShowConfirmPassword, onMouseDown: handleMouseDownConfirmPassword }, { children: showConfirmPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) })) })) })) })), _jsx("div", __assign({ className: s.error }, { children: errors.confirm_password && (_jsx("p", { children: _jsx(_Fragment, { children: (_b = errors.confirm_password) === null || _b === void 0 ? void 0 : _b.message }) })) }))] })));
            } }) }));
};
